import { useEffect } from 'react';
import CoverImage from '../../components/CoverImage';

interface IProject {
    title: string;
    description: string;
    image: string;
    link: string;
}

const Project = ({ title, description, image, link }: IProject) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            onClick={() => {
                if (link) {
                    window.location.href = link;
                }
            }}
            style={{ cursor: link ? 'pointer' : 'default' }}
            className="row mb-5 mt-5"
        >
            <div className="col-12 col-md-6 d-block d-sm-none">
                <img src={image} alt={title} style={{ width: '100%' }} />
            </div>
            <div className="col-12 col-md-6 pt-4">
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
            <div className="col-12 col-md-6 d-none d-sm-block">
                <img src={image} alt={title} style={{ width: '100%' }} />
            </div>
        </div>
    );
};

const Projects = () => {
    const projects: IProject[] = [
        {
            title: 'ItsMyFuture',
            description:
                'AE Capital Partners manages the ItsMyFuture initiative that provides Grade 12 learners with dedicated support in providing information about what careers to study, which registered institutions to study, and how to finance their education.',
            image: '/img/projects/its-my-future.png',
            link: '',
        },
        {
            title: 'SME Ignite',
            description:
                'To bridge the digital divide that is growing in South Africa, AE Capital manages the SME Ignite initiative which assists Small, Medium and Micro enterprises to increase their audience by creating and managing their online presence, making the most of the digital platforms available to them.',
            image: '/img/projects/sme-ignite.png',
            link: '',
        },
        {
            title: 'Blackbridge',
            description:
                'Blackbridge is a Property Sector initiative designed to increase the number of Black South African participants within the entire Property Sector. This includes but is not limited to increasing the number of Black property owners, developers, conveyancers, Property agencies and agents within the South African Property.',
            image: '/img/projects/blackbridge-property.png',
            link: '',
        },
    ];
    return (
        <div>
            <CoverImage title="Our Flagship Initiatives" />
            <div className="container">
                {projects.map((project: IProject) => (
                    <Project {...project} />
                ))}
            </div>
        </div>
    );
};

export default Projects;
