import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BsFacebook } from 'react-icons/bs';
import {
    AiFillTwitterCircle,
    AiFillYoutube,
    AiFillInstagram,
    AiFillLinkedin,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

const Header = () => {
    return (
        <>
            <div className="TopNavbar"></div>
            <Navbar expand="lg" className="d-flex flex-column Header bg-light">
                <Container>
                    <Navbar.Brand href="/">
                        <img src="./img/logo.jpeg" style={{ width: '200px' }} alt="logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link as="span">
                                <Link to="/" className={`${styles.HeaderLink}`}>
                                    Home
                                </Link>
                            </Nav.Link>

                            <Nav.Link as="span">
                                <Link to="/about" className={`${styles.HeaderLink}`}>
                                    About Us
                                </Link>
                            </Nav.Link>

                            <Nav.Link as="span">
                                <Link to="/services" className={`${styles.HeaderLink}`}>
                                    Why Choose Us
                                </Link>
                            </Nav.Link>

                            <Nav.Link as="span">
                                <Link to="/projects" className={`${styles.HeaderLink}`}>
                                    Projects
                                </Link>
                            </Nav.Link>

                            <Nav.Link as="span">
                                <Link to="/contacts" className={`${styles.HeaderLink}`}>
                                    Contact
                                </Link>
                            </Nav.Link>

                            <Nav.Link href="#home">
                                <AiFillTwitterCircle />
                            </Nav.Link>
                            <Nav.Link href="#link">
                                <BsFacebook />
                            </Nav.Link>
                            <Nav.Link href="#home">
                                <AiFillYoutube />
                            </Nav.Link>
                            <Nav.Link href="#link">
                                <AiFillInstagram />
                            </Nav.Link>
                            <Nav.Link href="#link">
                                <AiFillLinkedin />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;
