import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className={`${styles.Footer}`}>
            <div className="container">
                <div className="row">
                    <div className="col-7">
                        <ul className={`${styles.FooterLinks}`}>
                            <li className={`${styles.FooterLink}`}>
                                <Link to="/">Home</Link>
                            </li>
                            <li className={`${styles.FooterLink}`}>
                                <Link to="/about">About Us</Link>
                            </li>
                            <li className={`${styles.FooterLink}`}>
                                <Link to="/services">Services</Link>
                            </li>
                            <li className={`${styles.FooterLink}`}>
                                <Link to="/contacts">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col">
                        <h5>Location</h5>
                        <p>
                            33 Ballyclare Drive <br />
                            Ballywoods Office Park <br />
                            Cedarwood House <br />
                            Bryanston, 2191
                        </p>
                        <h5>Phone</h5>
                        <p>+27 63 601 7584</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
