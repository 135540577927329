import styles from './Hero.module.scss';

function Hero() {

  return (
    <div className={styles.Hero}>
      <div  className={styles.Hero__overlay}>
        <div className="container d-flex justify-contents-start">
          <div className="row">
            <div className="col-12 justify-contents-start">
              <h1 className={styles.Hero__title }>Your Partner in Transformation</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
