import Hero from '../../components/Hero';
import Stats from '../../components/Stats';
import styles from './Home.module.scss';

const Home = () => {
    return (
        <div className={`${styles.Home}`}>
            <Hero />
            <div>
                <Stats />
            </div>

            <div className={`container ${styles.Home__container}`}>
                <div className={`row mt-5`}>
                    <div className={`col-12 col-md-5`}>
                        <h1 className={`${styles.Home__lead_text}`}>
                            Our Values – Trust, Consistency, and Commitment
                        </h1>
                    </div>
                    <div className={`col-12 col-md-6 row mb-5 pb-5 pt-5`}>
                        <p style={{ fontSize: 18 }}>
                            AE Capital is a 100% Black Owned BBBEE consultancy (Level 1
                            Contributor).
                        </p>
                        <p style={{ fontSize: 18 }}>
                            With nearly a decade's experience helping corporate South Africa execute
                            transformation strategies, we are perfectly positioned to partner with
                            your business on your transformation journey.
                        </p>
                        <p style={{ fontSize: 18 }}>
                            We invite you to join our growing list of clients, across various
                            industries in the South African economy, to easily navigate the world of
                            transformation, and achieve meaningful social impact
                        </p>
                        <div className="mt-3">
                            <button
                                className="btn btn-primary"
                                onClick={() => (window.location.href = '/contacts')}
                            >
                                Contact Us
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pb-5 pt-5" style={{ backgroundColor: '#D0D0D0' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 p-3">
                            <img
                                src="/img/home/1.jpg"
                                alt=""
                                style={{ width: '100%', height: '90%' }}
                            />
                        </div>
                        <div className="col-12 col-md-6 p-3">
                            <img
                                src="/img/home/2.jpg"
                                alt=""
                                style={{ width: '100%', height: '90%' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
