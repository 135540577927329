import CoverImage from '../../components/CoverImage';
import { BsFacebook } from 'react-icons/bs';
import {
    AiFillTwitterCircle,
    AiFillYoutube,
    AiFillInstagram,
    AiFillLinkedin,
} from 'react-icons/ai';
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const Contacts = () => {
    const form = useRef(null);
    const [success, setSuccess] = useState<boolean>(false);

    return (
        <div>
            <CoverImage title="Contact Us" />
            <Modal show={success} onHide={() => setSuccess(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Message sent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Your message has been sent!</p>
                    <p>One of our Change Makers will contact you within 24 hours.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-primary"
                        onClick={() => {
                            setSuccess(false);
                            window.location.href = '/contacts';
                        }}
                    >
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="container mt-5 mb-5">
                <div className="row pt-5 pb-5">
                    <div className="col-12 col-md-8">
                        <div className="mb-3">
                            <a href="#home" className="m-2">
                                <AiFillTwitterCircle size={28} color="gray" />
                            </a>
                            <a href="#link" className="m-2">
                                <BsFacebook size={28} color="gray" />
                            </a>
                            <a href="#home" className="m-2">
                                <AiFillYoutube size={28} color="gray" />
                            </a>
                            <a href="#link" className="m-2">
                                <AiFillInstagram size={28} color="gray" />
                            </a>
                            <a href="#link" className="m-2">
                                <AiFillLinkedin size={28} color="gray" />
                            </a>
                        </div>
                        <div className="ImagePlaceHolder mb-5">
                            <iframe
                                width="800"
                                height="500"
                                style={{ border: 0 }}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCa8-lscJG3WhkPBvTW1x1L8C2VU3s0S6k&q=Ballywoods+Office+Park+33+Ballyclare+Drive+Bryanston+2192"
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 pt-5">
                        <p>
                            Should you need more information or are interested in starting your
                            transformation journey please complete the contact form below and one of
                            our Change Makers will contact you within 24 hours.
                        </p>
                        <form
                            ref={form}
                            onSubmit={(e) => {
                                e.preventDefault(); // prevents the page from reloading when you hit “Send”
                                if (form && form.current) {
                                    emailjs
                                        .sendForm(
                                            'service_2h7u0cl',
                                            'template_evt2diy',
                                            form.current,
                                            '_kkYLCxLa8SrUBPrw'
                                        )
                                        .then(
                                            (result) => {
                                                console.log(result);
                                                if (result.status === 200) {
                                                    setSuccess(true);
                                                }
                                            },
                                            (error) => {
                                                // show the user an error
                                            }
                                        );
                                }
                            }}
                        >
                            <div className="form-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    placeholder="Name"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <textarea className="form-control" name="message" rows={3}>
                                    Write your message
                                </textarea>
                            </div>
                            <div className="form-group mb-3">
                                <button type="submit" className="btn btn-primary btn-block">
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
