
import './App.module.scss';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contacts from './pages/Contacts';
import { Routes, Route, Outlet } from "react-router-dom";
import Projects from './pages/Projects';


function Layout() {
    return (<div>
        <Outlet />
    </div>)
}

function App() {
    return (<>
    <Header />
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="services" element={<Services />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="projects" element={<Projects />} />
            </Route>
        </Routes>
    <Footer />
    </>

    );
}

export default App;
