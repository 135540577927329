import CoverImage from '../../components/CoverImage';
import Carousel from 'react-bootstrap/Carousel';

const slider: any = [
    {
        source: '/img/services/2.jpg',
        alt: '',
    },
    {
        source: '/img/services/3.jpg',
        alt: '',
    },
    {
        source: '/img/services/4.jpg',
        alt: '',
    },
    {
        source: '/img/services/5.jpg',
        alt: '',
    },
];

const Services = () => {
    return (
        <div>
            <CoverImage title="Why Choose Us?" />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 pt-5">
                        <h1 className="mb-3">Our Approach</h1>
                        <p className="lead mb-3">
                            Industry leading relationships with Communities and Government at both
                            Local and Provincial level
                        </p>
                        <p>
                            To maintain our 85% success rate, we have invested heavily in building
                            strong and credible relationships in community engagement. This is
                            achieved through robust engagement with our partners which include
                            Provincial and Local governments, Non-Profit Organizations and Religious
                            Groups. We do not see communities as beneficiaries but more as partners
                            in development.
                        </p>
                    </div>
                    <div className="col-12 col-md-6 ImagePlaceHolder mt-3 mb-5">
                        <img
                            className="img-responsive"
                            src="/img/services/stats.png"
                            alt="Placeholder"
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 ImagePlaceHolder mt-3 mb-5 d-none d-sm-block">
                        <Carousel controls={false} indicators={false} interval={2000}>
                            {slider.map((slide: any) => (
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={slide.source}
                                        alt={slide.alt}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-12 col-md-6 pt-5">
                        <p className="lead mb-3">
                            A deep understanding on how to leverage collaboration and get the most
                            out of partnerships.
                        </p>
                        <p>
                            Active Edge Capital Partner views CSR, Enterprise Development and
                            Supplier Development as useful tools for business growth. When
                            implemented in isolation they allow you to tick a box, but when included
                            in your sales and communication strategy, they have the power to open
                            new markets, build Brand reputation, and foster positive employee
                            wellness.
                        </p>

                        <div className="mt-5">
                            <button
                                className="btn btn-primary"
                                onClick={() => (window.location.href = '/contacts')}
                            >
                                Contact Us
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
