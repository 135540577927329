import React from 'react';
import styles from './Stats.module.scss';

export default function Stats() {
    return (
        <div className={`container ${styles.Stats}`}>
            {/* <div className="row" style={{ marginLeft: 0 }}>
                <div className="col-12 d-flex flex-column">
                    <div className="d-flex">
                        <div
                            className={`${styles.Stats__column}`}
                            style={{
                                width: 36,
                                paddingTop: 24,
                                paddingRight: 0,
                                fontWeight: 'bold',
                            }}
                        >
                            Over
                        </div>
                        <div
                            className={`${styles.Stats__column}`}
                            style={{ fontSize: '5em', paddingLeft: 0, width: '72%' }}
                        >
                            R88
                        </div>
                        <div
                            className={`${styles.Stats__column}`}
                            style={{
                                fontSize: '1.5em',
                                paddingTop: 24,
                                paddingLeft: 0,
                                fontWeight: 'bold',
                            }}
                        >
                            MILLION INVESTED
                        </div>
                    </div>
                    <div style={{ width: '100%', fontSize: 18, marginTop: -24 }}>
                        In community development programs
                    </div>
                </div>
            </div>
            <div className="col-12 d-flex flex-column">
                <div className="d-flex">
                    <div
                        className={`${styles.Stats__column}`}
                        style={{
                            width: 26,
                            paddingTop: 10,
                            marginRight: 10,
                            paddingLeft: 12,
                            fontWeight: 'bold',
                            fontSize: '2.5em',
                        }}
                    >
                        +
                    </div>
                    <div
                        className={`${styles.Stats__column}`}
                        style={{ fontSize: '5em', paddingLeft: 0, width: '66%' }}
                    >
                        R8.4
                    </div>
                    <div
                        className={`${styles.Stats__column}`}
                        style={{
                            fontSize: '1.5em',
                            paddingTop: 24,
                            paddingLeft: 0,
                            fontWeight: 'bold',
                        }}
                    >
                        MILLION INVESTED IN SMMEs
                    </div>
                </div>
                <div style={{ fontSize: 18, paddingLeft: 10 }}>
                    through Enterprise & Supplier Development
                </div>
            </div> */}
            <div className="d-md-block d-none">
                <img src="/img/stats/desktop.jpeg" style={{ width: '100%' }} />
            </div>
            <div className="d-md-none">
                <img src="/img/stats/mobile.jpeg" style={{ width: '100%' }} />
            </div>
        </div>
    );
}
