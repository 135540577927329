import styles from './CoverImage.module.scss';
type CoverImageProps = {
    title: string;
}


const CoverImage = ({ title }: CoverImageProps) => {

    return (<div className={`${styles.CoverImage}`}>
        <h1>{title}</h1>
        </div>);
}

export default CoverImage;
