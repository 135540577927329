export const slider = [
    {
        source: '/img/about/3.jpg',
        alt: '',
    },
    {
        source: '/img/about/4.jpg',
        alt: '',
    },
    {
        source: '/img/about/5.jpg',
        alt: '',
    },
    {
        source: '/img/about/6.jpg',
        alt: '',
    },
    {
        source: '/img/about/7.jpg',
        alt: '',
    },
    {
        source: '/img/about/8.jpg',
        alt: '',
    },
    {
        source: '/img/about/9.jpg',
        alt: '',
    },
    {
        source: '/img/about/10.jpg',
        alt: '',
    },
    {
        source: '/img/about/11.jpg',
        alt: '',
    },
    {
        source: '/img/about/12.jpg',
        alt: '',
    },
    {
        source: '/img/about/13.jpg',
        alt: '',
    },
    {
        source: '/img/about/14.jpg',
        alt: '',
    },
];

export const partners = [
    {
        source: '/img/partners/1.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/2.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/3.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/4.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/5.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/6.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/7.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/9.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/11.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/12.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/13.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/15.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/16.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/17.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/18.jpeg',
        alt: '',
    },
    {
        source: '/img/partners/19.jpeg',
        alt: '',
    },
];
